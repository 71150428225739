import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface IPortal {
    disablePortal?: boolean;
}

const Portal: React.FC<IPortal> = props => {
    const { disablePortal } = props;
    const body = document.body;
    const elem = useRef(document.createElement('div'));
    elem.current.style.position = 'absolute';
    elem.current.style.top = '0';
    elem.current.style.left = '0';
    elem.current.style.width = '100%';
    elem.current.style.zIndex = '1000';

    useEffect(() => {
        const elm = elem.current;
        !disablePortal && body && body.appendChild(elm);
        return () => {
            !disablePortal && body && body.removeChild(elm);
        };
    }, [elem, body, disablePortal]);

    if (disablePortal) return <>{props.children}</>;
    return createPortal(props.children, elem.current);
};

export default Portal;
