import Logo from '@components/_common/Logo';
import { Link } from '@utils/i18n';
import React from 'react';
import styled from 'styled-components';
import { bp } from '@components/_struct/variables';
import { useMSTranslation } from '@utils/useMSTranslation';

const FooterMenu = styled.ul`
    li + li::before {
        content: '|';
        margin-left: 10px;
        margin-right: 10px;
        opacity: 0.5;
        @media (${bp.lg}) {
            display: none;
        }
    }
    a {
        color: #fff;
    }
`;

const LogoCont = styled.div`
    width: 103px;
`;

const Footer = () => {
    const { __ } = useMSTranslation(['cgu', 'mentions']);

    return (
        <>
            <div className='lg:flex w-full items-center p-2'>
                <div className='lg:w-1/3 text-center lg:text-left'>
                    © 2021 Viseet
                </div>
                <div className='lg:w-1/3 mt-2 flex justify-center lg:mt-0'>
                    <LogoCont>
                        <Logo variant='white' />
                    </LogoCont>
                </div>
                <div className='lg:w-1/3 mt-2 lg:mt-0'>
                    <FooterMenu className='list-none m-0 p-0 text-center lg:flex lg:justify-end'>
                        <li>
                            <Link href='/p/mentions'>
                                <a>{__('Mentions légales', 'mentions')}</a>
                            </Link>
                        </li>
                        <li>
                            <Link href='/p/cgu'>
                                <a>
                                    {__(
                                        'Conditions Générales d’Utilisation',
                                        'cgu'
                                    )}
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href='/p/donnees'>
                                <a>{__('Données personnelles', 'donnees')}</a>
                            </Link>
                        </li>
                    </FooterMenu>
                </div>
            </div>
        </>
    );
};

export default Footer;
