import React from 'react';
import DropdownContext from '../DropdownContext';
import Button from '../Button';
import { useMSTranslation } from '@utils/useMSTranslation';
import { useMediaQuery } from 'react-responsive';
import NoSSR from '../NoSSR';

interface IDropdownLangue {
    variant: 'white' | 'dark';
    /** Placement du dropdown par rapport au bouton */
    placement?: 'bottom-end' | 'bottom' | 'bottom-start';
}

const DropdownLangue = (props: IDropdownLangue) => {
    const { variant, placement } = props;
    const isTablet = useMediaQuery({ maxWidth: 768 });

    const { i18n } = useMSTranslation();

    const variantBtn =
        (variant == 'white' && 'outline-white') ||
        (variant == 'dark' && 'outline') ||
        'outline';

    return (
        <NoSSR>
            <DropdownContext
                customTrigger={
                    <Button
                        leftIcon={'globe-europe'}
                        variant={variantBtn}
                        size={isTablet ? 'small' : undefined}
                    >
                        {i18n.language === 'fr' ? 'Français' : 'English'}
                    </Button>
                }
                offset={5}
                placement={placement}
            >
                <DropdownContext.Item
                    onClick={() => i18n.changeLanguage('fr')}
                    active={i18n.language === 'fr'}
                >
                    Français
                </DropdownContext.Item>
                <DropdownContext.Item
                    onClick={() => i18n.changeLanguage('en')}
                    active={i18n.language === 'en'}
                >
                    English
                </DropdownContext.Item>
            </DropdownContext>
        </NoSSR>
    );
};

export default DropdownLangue;
