import React from 'react';

interface INoSSR {
    fallback?: JSX.Element;
    children: React.ReactNode;
}

const NoSSR = (props: INoSSR) => {
    const { fallback, children } = props;
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => setMounted(true), []);

    if (!mounted) {
        if (fallback) {
            return fallback;
        } else {
            return null;
        }
    }

    return <>{children}</>;
};

export default NoSSR;
