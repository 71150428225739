import styled from 'styled-components';

export const TriggerDropdownContext = styled.button`
    background: transparent;
    border: 0 none;
    border-radius: 50%;
    padding: 8px 0;
    width: 32px;
    height: 32px;
    text-align: center;
    transition: all 0.15s ease;
    font-size: 16px;
    color: #1c287f;
    outline: 0;
    &:hover,
    &.isopen {
        background: #f1f1f1;
    }
    &.clear-white {
        color: #fff;
        &:hover,
        &.isopen {
            background: inherit;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
        }
    }
    &.button-small {
        width: 28px;
        height: 28px;
        padding: 5px 0;
    }
`;

export const DropdownContextList = styled.ul`
    z-index: 1050;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;
    border-radius: 3px;
    font-size: 1.5rem;
    color: #000;
    border: 1px solid rgba(28, 40, 127, 0.5);
    user-select: none;
`;

export const DropdownContextItem = styled.li`
    padding: 8px 15px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    position: relative;
    &::before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 4px;
        background: #3a90e1;
        display: none;
    }
    &.active {
        font-weight: bold;
    }
    &:hover,
    &:hover a {
        color: #3a90e1;
    }
    &:hover::before {
        display: block;
    }
    & + li {
        border-top: 1px solid #e9eaf2;
    }
    a {
        text-decoration: none;
        color: #000;
    }
`;

export const DropdownContextName = styled.li`
    border-top: 0;
    padding: 11px 15px;
    background: #f0f2f5;
    font-weight: bold;
    white-space: nowrap;
    cursor: default;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    user-select: none;
    div {
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
