import React, {
    useState,
    Children,
    isValidElement,
    cloneElement,
    useRef
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    DropdownContextList,
    DropdownContextItem,
    TriggerDropdownContext,
    DropdownContextName
} from './DropdownContext.css';
import { Router } from '@utils/i18n';
import { usePopper } from 'react-popper';
import Portal from '../Portal';
import useOnClickOutside from '@utils/useOnClickOutside';

interface IDropdownContextItem {
    Item: React.FC<IOptionsContextItem>;
}

interface IDropdownContext {
    /** Affiche un titre au dropdown pour rappeler le contexte */
    context?: string;
    /** change le style du bouton par défaut */
    triggerVariant?: 'clear-white';
    /** callback à l'ouverture du dropdown */
    onOpen?: () => void;
    /** callback à la fermeture du dropdown */
    onClose?: () => void;
    /** Placement du dropdown par rapport au boutton */
    placement?: 'bottom-end' | 'bottom' | 'bottom-start';
    /** Taille du bouton par défaut */
    sizeBtn?: 'small';
    /** Bouton custom */
    customTrigger?: JSX.Element;
    /** désactiver le portal */
    disablePortal?: boolean;
    /** espace entre la trigger et le popover */
    offset?: number;
}

interface IOptionsContextItem {
    href?: string;
    as?: string;
    onClick?: () => void;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: () => void;
    active?: boolean;
}

const OptionsContextItem: React.FC<IOptionsContextItem> = props => {
    const { onClick, setIsOpen, href, as, onClose, active } = props;

    const handleClick = () => {
        onClick && onClick();
        if (href) {
            as ? Router.push(href, as) : Router.push(href);
        }
        onClose && onClose();
        setIsOpen && setIsOpen(false);
    };

    const activeClassName = active ? 'active' : '';

    return (
        <DropdownContextItem
            onClick={handleClick}
            className={`${activeClassName}`}
        >
            {props.children}
        </DropdownContextItem>
    );
};

const DropdownContext: React.FC<IDropdownContext> &
    IDropdownContextItem = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [
        referenceElement,
        setReferenceElement
    ] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(
        null
    );
    const ListRef = useRef<HTMLUListElement>(null);

    const {
        context,
        triggerVariant,
        onOpen,
        onClose,
        placement,
        sizeBtn,
        customTrigger,
        disablePortal,
        offset
    } = props;

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: !placement ? 'bottom-end' : placement,
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    tether: true,
                    rootBoundary: 'viewport',
                    altAxis: true // false by default
                }
            },
            {
                name: 'offset',
                options: {
                    offset: [0, offset ? offset : 0]
                }
            }
        ]
    });

    const handleClose = () => {
        onClose && onClose();
        setIsOpen(false);
    };

    useOnClickOutside(ListRef, handleClose);

    const handleClick = () => {
        onOpen && onOpen();
        setIsOpen(c => !c);
    };

    const isOpenClassName = isOpen ? 'isopen' : '';
    const triggerVariantClassName =
        triggerVariant == 'clear-white' ? 'clear-white' : '';
    const sizeTriggerClassName = sizeBtn == 'small' ? 'button-small' : '';

    const renderTrigger = () => {
        if (customTrigger != undefined) {
            return cloneElement(customTrigger, { onClick: handleClick });
        } else {
            return (
                <TriggerDropdownContext
                    onClick={handleClick}
                    className={`${isOpenClassName} ${triggerVariantClassName} ${sizeTriggerClassName}`}
                    type='button'
                >
                    <FontAwesomeIcon icon={['far', 'ellipsis-v']} />
                    <span className='sr-only'>Actions</span>
                </TriggerDropdownContext>
            );
        }
    };

    return (
        <div
            ref={setReferenceElement}
            className={`inline-block ${isOpenClassName}`}
        >
            {renderTrigger()}
            {isOpen && (
                <Portal disablePortal={disablePortal}>
                    <div
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <DropdownContextList ref={ListRef}>
                            {context && (
                                <DropdownContextName>
                                    <div>{context}</div>
                                </DropdownContextName>
                            )}
                            {Children.map(props.children, child => {
                                if (isValidElement(child)) {
                                    if (child.type == OptionsContextItem) {
                                        return cloneElement(child, {
                                            setIsOpen: setIsOpen,
                                            onClose: onClose
                                        });
                                    } else return child;
                                }
                            })}
                        </DropdownContextList>
                    </div>
                </Portal>
            )}
        </div>
    );
};

DropdownContext.Item = OptionsContextItem;

export default DropdownContext;
