import React from 'react';
import styled from 'styled-components';

interface ILogo {
    variant?: 'white' | 'blue';
    className?: string;
    width?: number;
}

const LogoCont = styled.div<{ $width?: number }>`
    width: ${p => (p.$width ? p.$width + 'px' : 'auto')};
    img {
        display: block;
        width: 100%;
    }
`;

const Logo = (props: ILogo) => {
    const { variant, className, width } = props;

    return (
        <LogoCont className={className} $width={width}>
            {variant == 'blue' ? (
                <img src='/images/viseet-blue.svg' alt='' />
            ) : variant == 'white' ? (
                <img src='/images/viseet-white.svg' alt='' />
            ) : (
                <img src='/images/viseet-blue.svg' alt='' />
            )}
        </LogoCont>
    );
};

export default Logo;
